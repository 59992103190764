import React from "react";
import {
  BlogCard,
  ExternalLink,
  HeaderThree,
  Tag,
  TagList,
  TitleContent,
  UtilityList,
  Img,
  Container,
  ImageContainer,
} from "./ProjectsStyles";
import {
  Section,
  SectionText,
  SectionTitle,
} from "../../styles/globalComponentsStyles";

const Projects = ({ projects, title }) => {
  return (
    <Section column id={"projects"}>
      <SectionTitle separate>{title}</SectionTitle>
      {projects.map(
        ({ title, description, image, tags, source, visit, id }) => (
          <Container key={id}>
            <ImageContainer>
              <a href={visit} target="_blank" rel="noreferrer">
                <Img src={image} />
              </a>
            </ImageContainer>
            <BlogCard key={id}>
              <TitleContent>
                <HeaderThree bigger>{title}</HeaderThree>
              </TitleContent>
              <SectionText justify smaller description>
                {description}
              </SectionText>
              <div>
                <TagList>
                  {tags.map((tag, index) => (
                    <Tag key={index}>{tag}</Tag>
                  ))}
                </TagList>
              </div>
              <UtilityList>
                <ExternalLink href={visit} target="_blank" rel="noreferrer">
                  Website
                </ExternalLink>
                <ExternalLink href={source} target="_blank" rel="noreferrer">
                  Code
                </ExternalLink>
              </UtilityList>
            </BlogCard>
          </Container>
        )
      )}
    </Section>
  );
};

export default Projects;
