// import portfolioOld from "../images/portfolio-old.png";
import portfolio from "../images/portfolio.png";
import ri from "../images/ri.png";
import dd from "../images/dd.png";
import ge from "../images/ge.png";
import iw from "../images/iw.png";
import wyin from "../images/wyin.png";
import starBlocks from "../images/star-blocks.png";

export const projects = [
  {
    title: "Geoevents",
    description:
      "It is a platform for making contacts, spending time together and promoting activities or businesses.\nUsers can create their own events on the map (e.g. presentation of a new application in the office or training together in the park). They can also sign up and participate in existing ones. In addition, users can place their own business on the map in order to attract customers (e.g. computer service).\nEach user has a board where they can create posts and comment on the posts of people they follow. They get notifications about the activities of others too. There is also a chat to arrange various details of meetings, etc.",
    image: ge,
    tags: [
      "Geolocation",
      "Global map",
      "Live chat",
      "Live notifications",
      "Automated tests",
      "Typescript",
      "React",
      "Redux",
      "Node / Express",
      "Material UI",
      "OAuth / JWT",
      "Sending emails",
      "Password recovery",
      "MongoDB",
    ],
    source: "https://github.com/lukaszmielczarekdev/geoevents",
    visit: "https://geoevents.pages.dev",
    id: 0,
  },
  {
    title: "Daily Diet",
    description:
      "Daily Diet allows you to create and manage diet diaries from scratch. It has a basic dietary guide with useful tips, a caloric calculator and a large product database to which you can add your own. Users can also rate each other's diaries as feedback.",
    image: dd,
    tags: [
      "React",
      "Node / Express",
      "Styled Components",
      "Redux",
      "Atomic Design",
      "OAuth / JWT",
      "Sending emails",
      "Password recovery",
      "REST API",
      "MongoDB",
      "React Testing Library / Jest",
    ],
    source: "https://github.com/lukaszmielczarekdev/daily-diet",
    visit: "https://daily-diet.pages.dev",
    id: 1,
  },
  {
    title: "Rocket English - Space Edition",
    description:
      "The application uses a gamification system to activate and motivate the user to learn English, who, as a reward for learning and overcoming challenges, wins trophies and gains access to the next levels of the game. The application has a built-in test creation mechanism and in addition to overcoming story challenges, it offers the user to create their own challenges based on the provided materials.",
    image: ri,
    tags: [
      "React",
      "Node / Express",
      "Redux",
      "REST API",
      "MongoDB",
      "PropTypes",
      "OAuth / JWT",
      "Sending emails",
      "Password recovery",
      "React Testing Library / Jest",
    ],
    source: "https://github.com/lukaszmielczarekdev/rocket-english",
    visit: "https://rocket-english.pages.dev",
    id: 2,
  },
  {
    title: "WYIN - What Year Is Now? (Graduation project)",
    description:
      "The app converts the current time to a specific year and displays historical events based on that date. It also allows the user to search for a specific date or draw a random one.",
    image: wyin,
    tags: ["React", "JIRA", "Teamwork", "Code review"],
    source: "https://github.com/lukaszmielczarekdev/wyin-fe-webapp",
    visit: "https://app-ci.spio-wyin.workers.dev",
    id: 3,
  },
  {
    title: "Portfolio",
    description:
      "A new version of the portfolio using more advanced solutions. Here, I used tools that I have learned from the time I built the first version of the portfolio.",
    image: portfolio,
    tags: ["React", "Styled Components"],
    source: "https://github.com/lukaszmielczarekdev/my-portfolio",
    visit: "https://lukasz-mielczarek.pages.dev",
    id: 4,
  },
  {
    title: "Imagewave",
    description:
      "Assistant for downloading images from the Internet. The API has queuing and is resistant to restarts.",
    image: iw,
    tags: [
      "Queue",
      "Typescript",
      "React",
      "Node / Express",
      "Material UI",
      "MongoDB",
    ],
    source: "https://github.com/lukaszmielczarekdev/pobieraczobrazkow",
    visit: "https://imagewave.pages.dev",
    id: 5,
  },
  // {
  //   title: "Portfolio",
  //   description:
  //     "The first version of the portfolio. Simple and modest. It is the first independent project that I have done from start to finish.",
  //   image: portfolioOld,
  //   tags: ["JavaScript", "Intersection Observer"],
  //   source: "https://github.com/lukaszmielczarekdev/portfolio",
  //   visit: "https://mielczarek-lukasz.pages.dev",
  //   id: 6,
  // },
];

export const experimentalProjects = [
  {
    title: "Star Blocks",
    description:
      "Implementation of a procedural terrain generator based on an algorithm for generating gradient noise - known as perlin noise.\n\nIn 1997, the author of the algorithm, Ken Perlin, won an Oscar in the technical category.\n\nDesign inspired by Minecraft game.",
    image: starBlocks,
    tags: ["JavaScript", "Perlin noise", "Procedural generation"],
    source: "https://github.com/lukaszmielczarekdev/star-app-3d",
    visit: "https://star-blocks-lm.pages.dev/",
    id: 6,
  },
];

export const TimeLineData = [
  { year: 2021, text: "Created the WYIN app with my team." },
  {
    year: 2021,
    text: "Completed postgraduate studies in Software Engineering.",
  },
  { year: "2022", text: "Finished the Rocket English app." },
  { year: "2022", text: "Finished the Daily Diet app." },
  { year: "2023", text: "Finished the Geoevents app." },
];
