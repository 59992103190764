export const breakpoints = {
  0: {
    items: 1,
  },
  550: {
    items: 2,
  },
  760: {
    items: 3,
  },
  960: {
    items: 3,
  },
  1022: {
    items: 3,
  },
  1278: {
    items: 4,
  },
  1610: {
    items: 5,
  },
  2550: {
    items: 6,
  },
};
