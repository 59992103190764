import React from "react";
import { DiJavascript1, DiJira, DiNpm } from "react-icons/di";
import {
  SiStyledcomponents,
  SiJest,
  SiRedux,
  SiTypescript,
  SiJsonwebtokens,
  SiNodedotjs,
  SiMongodb,
  SiTestinglibrary,
  SiHtml5,
  SiExpress,
  SiGithub,
  SiMaterialui,
  SiSocketdotio,
  SiReactrouter,
  SiReact,
  SiCss3,
} from "react-icons/si";
import { AiOutlineTeam } from "react-icons/ai";
import { TbApi, TbAtom2 } from "react-icons/tb";
import { ToolsList, ToolsItem, ToolsParagraph, ToolsTitle } from "./ToolStyles";
import { Section, SectionTitle } from "../../styles/globalComponentsStyles";

const Tools = () => {
  return (
    <Section column id={"tools"}>
      <SectionTitle separate>Tools</SectionTitle>
      <ToolsTitle>Experience with:</ToolsTitle>
      <ToolsParagraph>Front-End</ToolsParagraph>
      <ToolsList margin>
        <ToolsItem>
          <SiReact size={"1.5rem"} style={{ marginRight: "0.3rem" }} /> React
        </ToolsItem>
        <ToolsItem>
          <SiReactrouter size={"1.5rem"} style={{ marginRight: "0.3rem" }} />{" "}
          React Router
        </ToolsItem>
        <ToolsItem>
          <SiTypescript size={"1.4rem"} style={{ marginRight: "0.3rem" }} />
          TypeScript
        </ToolsItem>
        <ToolsItem>
          <SiCss3 size={"1.4rem"} style={{ marginRight: "0.3rem" }} />
          CSS&nbsp;3
        </ToolsItem>
        <ToolsItem>
          <DiJavascript1 size={"1.6rem"} style={{ marginRight: "0.1rem" }} />
          JavaScript
        </ToolsItem>
        <ToolsItem>
          <SiHtml5 size={"1.4rem"} style={{ marginRight: "0.3rem" }} />
          HTML&nbsp;5
        </ToolsItem>
        <ToolsItem>
          <SiJest size={"1.5rem"} style={{ marginRight: "0.3rem" }} /> Jest
        </ToolsItem>
        <ToolsItem>
          <SiTestinglibrary size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          React Testing Library
        </ToolsItem>
        <ToolsItem>
          <SiRedux size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          Redux / RTK
        </ToolsItem>
        <ToolsItem>
          <SiReact size={"1.5rem"} style={{ marginRight: "0.3rem" }} /> React
          Context
        </ToolsItem>
        <ToolsItem>
          <SiSocketdotio size={"1.5rem"} style={{ marginRight: "0.4rem" }} />
          Socket.io
        </ToolsItem>
        <ToolsItem>
          <SiStyledcomponents size={"2rem"} style={{ marginRight: "0.3rem" }} />
          Styled Components
        </ToolsItem>
        <ToolsItem>
          <SiMaterialui size={"1.5rem"} style={{ marginRight: "0.5rem" }} />
          Material UI
        </ToolsItem>
      </ToolsList>
      <ToolsParagraph>Back-End</ToolsParagraph>
      <ToolsList margin>
        <ToolsItem>
          <SiNodedotjs size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          Node
        </ToolsItem>
        <ToolsItem>
          <SiExpress size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          Express
        </ToolsItem>
        <ToolsItem>
          <DiNpm size={"2rem"} style={{ marginRight: "0.3rem" }} />
          NPM
        </ToolsItem>
      </ToolsList>
      <ToolsParagraph>Databases</ToolsParagraph>
      <ToolsList margin>
        <ToolsItem>
          <SiMongodb size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          MongoDB
        </ToolsItem>
      </ToolsList>
      <ToolsParagraph>Other</ToolsParagraph>
      <ToolsList margin>
        <ToolsItem>
          <SiJsonwebtokens size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          JSON Web Token
        </ToolsItem>
        <ToolsItem>
          <TbApi size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          REST
        </ToolsItem>
        <ToolsItem>
          <TbAtom2 size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          Atomic Design
        </ToolsItem>
        <ToolsItem>
          <DiJira size={"1.8rem"} />
          JIRA
        </ToolsItem>
        <ToolsItem>
          <AiOutlineTeam size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          Agile / Scrum
        </ToolsItem>
        <ToolsItem>
          <SiGithub size={"1.5rem"} style={{ marginRight: "0.3rem" }} />
          Git / GitHub
        </ToolsItem>
      </ToolsList>
    </Section>
  );
};

export default Tools;
